const TEN_MINUTES = 600000
const CONSTANTS = {
  SESSION_DURATION: TEN_MINUTES,
  API_AUTH_STATUS: {
    Pending: 'Pending',
    ExpiredPassword: 'ExpiredPassword',
    RequiresMFA: 'RequiresMFA',
    RequiresMFAAuthenticator: 'RequiresMFAAuthenticator',
    RequiresPhoneRegistration: 'RequiresPhoneRegistration',
    Success: 'Success',
    WrongCredentials: 'WrongCredentials',
  },
  SCOPES: {
    ADMIN: 'ADMIN',
    STORE: 'STORE',
  },
  AUDIENCE: {
    ADMIN: 'admin',
    STORE: 'webstore',
  },
}

export default CONSTANTS
