import NetworkError from './NetworkError'
import BlockedUserError from './BlockedUserError'
import WrongCredentials from './WrongCredentials'
import InvalidRecaptchaError from './InvalidRecaptchaError'
import OAuthError from './OAuthError'
import ThrottledError from './ThrottledError'
import PasswordAlreadyUsedError from './PasswordAlreadyUsedError'
import UnauthorizedUser from './UnauthorizedUser'

const errorTypeByCode = {
  NetworkError,
  BlockedUser: BlockedUserError,
  WrongCredentials,
  InvalidMFAToken: WrongCredentials,
  InvalidRecaptcha: InvalidRecaptchaError,
  OAuthError,
  ThrottledError,
  PasswordAlreadyUsed: PasswordAlreadyUsedError,
  UnauthorizedUser,
}

export default errorTypeByCode
