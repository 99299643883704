import { composeQuerystring } from '../utils'

const BASE_PATH = `/api/vtexid`

const paths = {
  getOAuthCallbackUrl: (isPopup) =>
    `${BASE_PATH}/oauth/finish?popup=${!!isPopup}`,
  sendEmailVerification: (deliveryMethod) =>
    `${BASE_PATH}/pub/authentication/accesskey/send${
      deliveryMethod ? `?deliveryMethod=${deliveryMethod}` : ''
    }`,
  startLogin: () => `${BASE_PATH}/pub/authentication/startlogin`,
  validatePassword: () => `${BASE_PATH}/pub/authentication/classic/validate`,
  validateToken: (setPreference) =>
    `${BASE_PATH}/pub/authentication/accesskey/validate?setPreference=${!!setPreference}`,
  getLoginPreference: (scope, email) => {
    const qs = composeQuerystring({ scope, email })

    return `${BASE_PATH}/pub/authentication/userpreference?${qs}`
  },
  redirect: (returnUrl) => {
    const qs = returnUrl ? composeQuerystring({ returnUrl }) : ''

    return `${BASE_PATH}/pub/authentication/redirect?${qs}`
  },
  startMfaSetup: () => `${BASE_PATH}/pvt/mfa/start`,
  registerMfaAuthenticator: () => `${BASE_PATH}/pub/mfa/registerAuthenticator`,
  registerMfaPhoneNumber: () => `${BASE_PATH}/pub/mfa/registerphone`,
  validateMfa: () => `${BASE_PATH}/pub/mfa/validate`,
  resendMfa: () => `${BASE_PATH}/pub/mfa/resend`,
  setPasswordAfterLogin: (scope, setPreference) => {
    return `${BASE_PATH}/user/password/quickset?scope=${scope}&setPreference=${!!setPreference}`
  },
  setPassword: () =>
    `${BASE_PATH}/pub/authentication/classic/setpassword?expireSessions=true`,
  getSamlRedirectUrl: (providerName) =>
    `${BASE_PATH}/pub/saml/${providerName}/redirect`,
  getOAuthRedirectUrl: ({ providerName, errorFallbackUrl }) =>
    `${BASE_PATH}/pub/authentication/oauth/redirect?${composeQuerystring({
      providerName,
      errorFallbackUrl,
    })}`,
  getIdentityProviders: (account, scope) =>
    `${BASE_PATH}/pub/authentication/providers?scope=${scope}&accountName=${account}`,
  reauthenticateUser: (audience) => `${BASE_PATH}/refreshtoken/${audience}`,
  userInfo: (scope) => `${BASE_PATH}/user/info?scope=${scope}`,
  logout: (scope, returnUrl) => {
    const qs = composeQuerystring({
      scope,
      returnUrl,
    })

    return `${BASE_PATH}/pub/logout?${qs}`
  },
}

export default paths
