export const buildFormData = (valueObject) => {
  const form = new FormData()

  Object.keys(valueObject).forEach((key) =>
    form.append(key, valueObject[key] || '')
  )

  return form
}

export const parseResBody = (res) => {
  const contentType = res.headers.get('Content-Type') || ''

  if (contentType.startsWith('application/json')) {
    return res.json()
  }

  return res.text()
}

export const composeQuerystring = (valueObject) => {
  return Object.keys(valueObject)
    .map((key) => `${key}=${encodeURIComponent(valueObject[key] || '')}`)
    .join('&')
}

export default composeQuerystring
