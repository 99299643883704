import { useCallback, useEffect, useState } from 'react'

import { NOOP } from '../../../constants'
import getError from '../error/getError'

const useAsyncCallback = (
  callback,
  depsLst,
  { onSuccess = NOOP, onFailure = NOOP, onFinish = NOOP, autorun = false } = {}
) => {
  const [value, setValue] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(autorun)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedCallback = useCallback(callback, depsLst)

  const wrappedCallback = useCallback(
    async (...args) => {
      setError(null)
      setLoading(true)
      try {
        const data = await memoizedCallback(...args)

        setValue(data)
        setError(null)
        onSuccess(data)

        return data
      } catch (err) {
        setError(err)
        setValue(null)
        onFailure(getError(err))

        return err
      } finally {
        setLoading(false)
        onFinish()
      }
    },
    [memoizedCallback, onFailure, onFinish, onSuccess]
  )

  useEffect(() => {
    if (autorun) {
      wrappedCallback()
    }
  }, [autorun, wrappedCallback])

  return [wrappedCallback, { value, error, loading }]
}

export default useAsyncCallback
