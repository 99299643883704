const buildReqHeaders = ({ contentType, parentAppId }) => {
  const uiPrefix = parentAppId ? `${parentAppId}/` : ''
  const selfAppId = process.env.VTEX_APP_ID || process.env.VTEX_APP_NAME || ''

  return {
    ...(contentType && { 'Content-Type': contentType }),
    'vtex-id-ui-version': `${uiPrefix}${selfAppId}`,
  }
}

export default buildReqHeaders
